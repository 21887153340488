document.addEventListener('DOMContentLoaded', () => {

	// Get all "navbar-burger" elements
	const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

	// Check if there are any navbar burgers
	if ($navbarBurgers.length > 0) {

		// Add a click event on each of them
		$navbarBurgers.forEach( el => {
			el.addEventListener('click', () => {

				// Get the target from the "data-target" attribute
				const target = el.dataset.target;
				const $target = document.getElementById(target);

				// Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
				el.classList.toggle('is-active');
				$target.classList.toggle('is-active');
				document.querySelector('.nav-mmenu').classList.toggle('is-active');
				document.querySelector('body').classList.toggle('is-mmenu');
			});
		});

		var ddItems = document.querySelectorAll('.has-dropdown');
		if (ddItems.length > 0) {
			ddItems.forEach(function (element, index) {
				var item =  element.querySelector(".navbar-link");
				var mmenu = document.createElement("span");
				mmenu.className = "dd-arrow";
				mmenu.innerHTML = '>';
				item.appendChild(mmenu);
			});
		}
	}

});

// Elements to inject
var elementsToInject = document.querySelectorAll('.injectme');
// Options
var injectorOptions = {
	evalScripts: 'once',
	pngFallback: 'image/fallback'
};
// Trigger the injection
var injector = new SVGInjector(injectorOptions);
injector.inject(
	elementsToInject
);
jQuery($ => {
	$(document).ready(function(){
		$(document).on('click', '.navbar-link', function(ev){
			ev.preventDefault();
			$(this).closest('.has-dropdown').toggleClass('dd-active');
			$(this).closest('.has-dropdown').children('.navbar-dropdown').slideToggle();
		});
		// $(document).on('click', '.dd-open', function(ev){
		// 	//ev.preventDefault();
		// 	$('.card-arrow').removeClass('chevron--up').addClass('chevron--down');
		// 	$('.navbar-burger').removeClass('hide-navbar');
		// 	$(this).removeClass('dd-open');
		// });
	});
});
